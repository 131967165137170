import React, { CSSProperties, useEffect, useMemo, useState } from 'react'

import './App.css'

import AppStateContext from '../../contexts/AppStateContext'
import { CircularProgress, Container, GlobalStyles, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import AppErrorBoundary from '../AppErrorBoundary/AppErrorBoundary'
import { Outlet, useNavigate } from 'react-router-dom'

import { ErrorType } from '../../types/error-type'
import { MyClubState, MyClubStateContext } from '../../types'
import axios from 'axios'

const CM_HASH_QUERY_PARAM = 'cmhash'

const fetchConfigs = async () => {
  const configsResponse = await fetch('configs.json')
  const manifestResponse = await fetch('manifest.json')

  if (!configsResponse.ok) {
    throw new Error(`Error: ${configsResponse.status}`)
  }

  if (!manifestResponse.ok) {
    throw new Error(`Error: ${manifestResponse.status}`)
  }

  if (configsResponse.status === 200 && manifestResponse.status === 200) {
    const config = await configsResponse.json()
    const manifest = await manifestResponse.json()

    return {
      ...config,
      ...manifest,
    }
  }
}

const fetchAuth = async (apiBaseUrl: string) => {
  const response = await fetch(`${apiBaseUrl}/me`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  })

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      throw new Error('Unauthorized')
    }
  }

  if (response.status === 200) {
    return response.json()
  }
}

const fetchPreRegister = async (apiBaseUrl: string, cmhash: string) => {
  const response = await fetch(`${apiBaseUrl}/preregister?hash=${cmhash}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })

  if (!response.ok) {
    if (response.status === 409) {
      throw ErrorType.USER_ALREADY_REGISTRED
    }

    throw new Error(`${response.status}: ${response.statusText}`)
  }

  if (response.status === 200) {
    return response.json()
  }
}

function AppLockOverlay() {
  const styles: CSSProperties = {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <div style={styles}>
      <Typography
        style={{ color: 'white', textAlign: 'center', fontSize: '24px' }}
      >
        Estamos a processar....
        <br />
        Por favor não feche esta aplicação
      </Typography>
      <CircularProgress style={{ color: 'white' }} />
    </div>
  )
}

function App() {
  const [appState, setAppState] = useState<MyClubState>({
    authenticated: false,
    isFetchingCritical: false,
    appName: 'My Club',
  })

  const patchState = (state: Partial<MyClubState>) => {
    setAppState((oldState) => ({ ...oldState, ...state }))
  }

  const { myClubStyles } = appState;

  const [error, setError] = useState(false)

  const [isCheckingAuth, setIsCheckingAuth] = useState(true)

  const navigate = useNavigate()

  const appStateContext = {
    ...appState,
    patchState,
    setState: (state) => {
      console.log('Someone is trying to set the state!', state);
      setAppState(state);
    },
    logout: () =>
      patchState({
        authenticated: false,
        member: undefined,
      }),
    setError: (error) =>
      patchState({
        error,
      }),
    discardError: () =>
      patchState({
        error: null,
      }),
  } as MyClubStateContext

  useEffect(() => {
    fetchConfigs()
      .then((config) => {
        patchState({
          apiBaseUrl: config.apiBaseUrl,
          appName: config.name,
          iconFile: config.icons[config.icons.length - 1].src,
        })
      })
      .catch(() => setError(error))
  }, [])

  useEffect(() => {
    if (!appState?.appName) {
      return
    }

    document.title = appState.appName
  }, [appState.appName])

  useEffect(() => {
    if (!appState.apiBaseUrl) {
      return
    }

    setIsCheckingAuth(true)

    fetchAuth(appState.apiBaseUrl)
      .then((member) => {
        patchState({
          authenticated: true,
          member,
        })
      })
      .catch((error: Error) => {
        if (error.message === 'Unauthorized') {
          navigate('/login')
        }
      })
      .finally(() => {
        setIsCheckingAuth(false)
      })

    const axiosClient = axios.create({
      baseURL: appState.apiBaseUrl,
      withCredentials: true,
    });

    axiosClient.get('configurations').then((response) => {
      const { myClubUrl, myClubStyles } = response.data;

      console.log(myClubStyles);

      patchState({ myClubUrl, myClubStyles })
    }, (error) => {
      console.error(error);
    });
  }, [appState.apiBaseUrl])

  console.log(appState);

  useEffect(() => {
    if (!appState.apiBaseUrl) {
      return
    }

    const queryParams = new URLSearchParams(window.location.search)
    const cmhash = queryParams.get(CM_HASH_QUERY_PARAM)

    if (!cmhash) {
      return
    }

    fetchPreRegister(appState.apiBaseUrl, cmhash)
      .then((member) => {
        patchState({
          authenticated: false,
          member,
        })
        navigate('/register')
      })
      .catch((error) => {
        if (error === ErrorType.USER_ALREADY_REGISTRED) {
          patchState({ error })
          navigate('/login', { replace: true })
        }
      })
  }, [appState.apiBaseUrl])


  useEffect(() => {
    if (appState.authenticated && appState.apiBaseUrl) {
      navigate('/')
    }
  }, [appState.authenticated, appState.apiBaseUrl])

  const globalStyles = useMemo(() => {
    if (!myClubStyles) return;

    const { primaryColor } = myClubStyles || {}

    return {
      'button.filled': {
        backgroundColor: `${primaryColor} !important`,
        color: 'white !important',
      },
      'button:not(.filled)': {
        backgroundColor: 'white !important',
        color: primaryColor,
      },
      'button.only-text': {
        backgroundColor: `transparent !important`,
        color: `${primaryColor} !important`,
      },
      '.MuiStepIcon-root': {
        color: `${primaryColor} !important`,
      },
    }
  }, [myClubStyles])

  return (
    <ErrorBoundary fallbackRender={AppErrorBoundary}>
      <AppStateContext.Provider value={appStateContext}>
        <GlobalStyles styles={globalStyles} />
        {isCheckingAuth ? (
          <Container className="flex-vertical flex-centered full-width full-height">
            <CircularProgress style={{ color: 'white' }} />
          </Container>
        ) : (
          <Container
            style={{
              padding: 0,
              height: 'auto',
              minHeight: '100%',
              width: '100%',
              minWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundSize: 'cover',
              backgroundImage: appState.myClubStyles?.backgroundImageUrl ? `url(${appState.myClubStyles?.backgroundImageUrl})` : 'none',
            }}
            className="min-full-height full-height"
          >
            <Outlet />
          </Container>
        )}

        {appState.isFetchingCritical ? <AppLockOverlay /> : null}
      </AppStateContext.Provider>
    </ErrorBoundary>
  )
}

export default App
