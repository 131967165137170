import React, { useContext, useEffect, useMemo, useState } from 'react'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import EventIcon from '@mui/icons-material/Event'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Box,
  Button,
  Slide,
  MobileStepper,
  Container,
} from '@mui/material'

import { Ticket, TicketingEvent, TicketsResponse } from '../../types'
import AppStateContext from '../../contexts/AppStateContext'

import './TicketingEventCard.scss'
import TicketsBuyCart from '../TicketsBuyCart/TicketsBuyCart'

import TicketCard from '../TicketCard/TicketCard'
import moment from 'moment-timezone'

type Props = {
  event: TicketingEvent
  mode?: 'normal' | 'compact'
  onClick?: () => void
}

enum TicketBuyingState {
  NOT_STARTED,
  SELECTING,
  REQUESTING,
  PAYING,
  COMPLETED,
  LISTING_MY_TICKETS,
}

const fetchMyTickets = async (apiBaseUrl: string, eventId: number) => {
  const response = await fetch(
    `${apiBaseUrl}/ticketing/events/${eventId}/my-tickets`,
    {
      credentials: 'include',
    }
  )
  return response.json() as Promise<TicketsResponse>
}

function TicketingEventCard({
  event,
  mode = 'normal',
  onClick = () => {},
}: Props) {
  const { apiBaseUrl } = useContext(AppStateContext)

  const [visibleTicket, setVisibleTicket] = useState(0)
  const [tickets, setTickets] = useState<Ticket[]>([])

  const [buyingState, setBuyingState] = useState<TicketBuyingState>(
    TicketBuyingState.NOT_STARTED
  )

  const ticketsAccessCategoriesIds = useMemo(
    () => tickets.map((ticket) => ticket.eventAccessCategoryId),
    [tickets]
  )

  const eventMemberExclusiveAccessCategoriesIds = useMemo(
    () =>
      event.accessCategories
        .filter((category) => category.memberExclusive)
        .map((category) => category.id),
    [event]
  )

  const memberHasExclusiveMemberTicket = useMemo(
    () =>
      ticketsAccessCategoriesIds.some((ticketAccessCategoryId) =>
        eventMemberExclusiveAccessCategoriesIds.includes(ticketAccessCategoryId)
      ),
    [ticketsAccessCategoriesIds, eventMemberExclusiveAccessCategoriesIds]
  )

  const fetchTickets = async () => {
    if (!apiBaseUrl) {
      return
    }

    const tickets = await fetchMyTickets(apiBaseUrl, event.id)

    setTickets(
      tickets.map((item) => ({
        id: item.id,
        uuid: item.uuid,
        price: item.price,
        eventId: item.event_id,
        eventAccessCategoryId: item.event_access_category_id,
        paid: item.movement_paid,
        used: item.used,
      }))
    )
  }

  useEffect(() => {
    fetchTickets()
  }, [])

  const handleTicketBuyClick = () => {
    if (!apiBaseUrl) {
      return
    }

    setBuyingState(TicketBuyingState.SELECTING)
  }

  const handleMyTicketsClick = () => {
    if (!apiBaseUrl) {
      return
    }

    setBuyingState(TicketBuyingState.LISTING_MY_TICKETS)
  }

  const handleMyTicketsCancel = () => {
    setBuyingState(TicketBuyingState.NOT_STARTED)
  }

  const handleTicketBuyComplete = () => {
    fetchTickets()
    setBuyingState(TicketBuyingState.LISTING_MY_TICKETS)
  }

  const handleTicketBuyCancel = () => {
    setBuyingState(TicketBuyingState.NOT_STARTED)
  }

  const handleNextTicket = () =>
    setVisibleTicket((prevTicket) => prevTicket + 1)
  const handlePrevTicket = () =>
    setVisibleTicket((prevTicket) => prevTicket - 1)

  const isPurchaseOpened =
    event.purchaseStart < new Date() && event.purchaseEnd > new Date()
  const isPurchaseClosed = event.purchaseEnd < new Date()
  const isPurchasable = event.accessCategories.some(
    (category) => category.enabled && category.price > 0
  )

  const dateRender = (
    <div className="flex-horizontal align-center gap-4">
      <EventIcon />{' '}
      <span>{moment(event.start).format('ddd DD/MMM HH:mm')}</span>
    </div>
  )

  const cardHeader = (
    <>
      <div className="cm-event-card__header">
        <Typography variant="h6" color="text.secondary">
          Próximo Evento
        </Typography>
        <Typography variant="h6">{dateRender}</Typography>
      </div>
    </>
  )

  const cardSubHeader =
    mode === 'compact' && event.template === 'details' ? (
      <Typography variant="h5">{event.name}</Typography>
    ) : null

  const compactRender = (
    <Card
      onClick={onClick}
      className={`TicketingEventCard 'TicketingEventCard--compact' ${
        event.template === 'image' ? 'cm-event-card--template-image' : ''
      }`}
      sx={{ maxWidth: 345 }}
    >
      <CardHeader title={cardHeader} subheader={cardSubHeader} />

      {event.pictureUrl ? (
        <CardMedia component="img" image={event.pictureUrl} alt="Paella dish" />
      ) : null}
    </Card>
  )

  return mode === 'compact' ? (
    compactRender
  ) : (
    <>
      <Card
        onClick={onClick}
        className={`TicketingEventCard ${
          event.template === 'image' ? 'cm-event-card--template-image' : ''
        }`}
        sx={{ maxWidth: 345 }}
        elevation={buyingState === TicketBuyingState.NOT_STARTED ? 1 : 10}
      >
        {event.pictureUrl && event.template === 'image' ? (
          <CardMedia
            component="img"
            image={event.pictureUrl}
            alt="Paella dish"
          />
        ) : null}

        <CardContent className="flex-1">
          {event.template === 'details' ? (
            <Box>
              {event.pictureUrl ? (
                <CardMedia
                  component="img"
                  image={event.pictureUrl}
                  alt="Paella dish"
                />
              ) : null}

              <CardHeader
                title={
                  event.template === 'details' ? (
                    <div>
                      <Typography variant="body2" color="text.secondary">
                        Próximo Evento
                      </Typography>
                      <Typography variant="h5">{event.name}</Typography>
                    </div>
                  ) : (
                    `${event.name}`
                  )
                }
                subheader={dateRender}
              />

              {mode === 'normal' ? (
                <Container className="cm-event-card__description">
                  <Typography variant="body2" color="text.secondary">
                    {event.description}
                  </Typography>
                </Container>
              ) : null}
            </Box>
          ) : null}

          {buyingState === TicketBuyingState.SELECTING && (
            <Slide
              in={buyingState === TicketBuyingState.SELECTING}
              direction="left"
            >
              <Box className="TicketingEventCard__purchase-tickets">
                <CardHeader title={event.name} subheader={dateRender} />
                <CardContent className="">
                  <TicketsBuyCart
                    memberHasExclusiveMemberTicket={
                      memberHasExclusiveMemberTicket
                    }
                    event={event}
                    onCancel={handleTicketBuyCancel}
                    onComplete={handleTicketBuyComplete}
                  ></TicketsBuyCart>
                </CardContent>
              </Box>
            </Slide>
          )}

          {buyingState === TicketBuyingState.LISTING_MY_TICKETS && (
            <Slide
              in={buyingState === TicketBuyingState.LISTING_MY_TICKETS}
              direction="left"
            >
              <Box className="TicketingEventCard__my-tickets">
                <CardHeader title={event.name} subheader="Os meus Bilhetes" />
                <CardContent>
                  <Container>
                    {tickets.length ? (
                      <TicketCard
                        event={event}
                        ticket={tickets[visibleTicket]}
                      />
                    ) : (
                      <div>Não tem bilhetes para este evento</div>
                    )}

                    {tickets.length ? (
                      <MobileStepper
                        variant="text"
                        steps={tickets.length}
                        position="static"
                        activeStep={visibleTicket}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNextTicket}
                            disabled={visibleTicket === tickets.length - 1}
                          >
                            Seguinte
                            <KeyboardArrowRight />
                          </Button>
                        }
                        backButton={
                          <Button
                            size="small"
                            onClick={handlePrevTicket}
                            disabled={visibleTicket === 0}
                          >
                            <KeyboardArrowLeft />
                            Anterior
                          </Button>
                        }
                      />
                    ) : null}
                  </Container>
                </CardContent>
              </Box>
            </Slide>
          )}
        </CardContent>

        {mode === 'normal' ? (
          <CardActions disableSpacing className="TicketingEventCard__actions">
            {buyingState === TicketBuyingState.NOT_STARTED &&
              mode === 'normal' &&
              isPurchasable && (
                <>
                  {isPurchasable && (
                    <Button
                      className="filled"
                      variant="outlined"
                      startIcon={<ShoppingCartCheckoutIcon />}
                      onClick={handleTicketBuyClick}
                    >
                      {isPurchaseOpened
                        ? 'Comprar bilhete'
                        : isPurchaseClosed
                        ? 'Venda já terminada'
                        : `Compra a partir de ${moment(
                            event.purchaseStart
                          ).format('DD/MM/YYYY HH:mm')}`}
                    </Button>
                  )}

                  {tickets.length > 0 && (
                    <Button
                      className="filled"
                      variant="outlined"
                      startIcon={<ConfirmationNumberIcon />}
                      onClick={handleMyTicketsClick}
                    >
                      Os meus bilhetes
                    </Button>
                  )}
                </>
              )}

            {buyingState === TicketBuyingState.SELECTING && (
              <Button
                variant="outlined"
                className="filled"
                onClick={handleTicketBuyCancel}
              >
                {isPurchaseOpened ? 'Cancelar' : 'Voltar'}
              </Button>
            )}

            {buyingState === TicketBuyingState.LISTING_MY_TICKETS && (
              <Button
                className="filled"
                variant="outlined"
                onClick={handleMyTicketsCancel}
              >
                Voltar
              </Button>
            )}
          </CardActions>
        ) : null}
      </Card>
    </>
  )
}

export default TicketingEventCard
