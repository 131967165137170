import { ErrorType } from './types/error-type'
import { Member } from './types/member'
import { Period } from './types/pending-period'

export enum PaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
  MBWAY = 'MBWAY',
  OFFER = 'OFFER',
  OTHER = 'OTHER',
  PAYPAL = 'PAYPAL',
  TRANSFER = 'TRANSFER',
  VOUCHER = 'VOUCHER',
  MB_REFERENCE = 'MB_REFERENCE',
}

type MyClubStyles = {
  primaryColor?: string;
  secondaryColor?: string;
  logoUrl?: string;
  backgroundImageUrl?: string;
};

export interface MyClubState {
  myClubUrl?: string
  myClubStyles?: MyClubStyles
  apiBaseUrl?: string
  appName?: string
  authenticated: boolean
  iconFile?: string
  isFetchingCritical: boolean
  error?: ErrorType | null
  member?: Member
}

export interface MyClubStateContext extends MyClubState {
  setState: (state: MyClubState) => void
  patchState: (partialState?: Partial<MyClubState>) => void
  logout: () => void
  discardError: () => void
  setError: (error: ErrorType) => void
}

export type MemberSituationData = {
  periods: Period[]
  pendingTotal: number
}

export type MemberSituationContextData = {
  situation: MemberSituationData
  setSituation: (situation: MemberSituationData) => void
  updateSituation: () => void
}

export type Country = {
  code: string
  label: string
  phone: string
  suggested?: boolean
}

export type TicketingEventAccessCategory = {
  id: number
  title: string
  description: string
  price: number
  memberExclusive: boolean
  capacity: number
  enabled: boolean
}

type TicketingEventAccessCategoryResponse = {
  id: number
  title: string
  description: string
  price: number
  member_exclusive: boolean
  capacity: number
  enabled: boolean
}

export type TicketingEventAccessCategoriesResponse =
  TicketingEventAccessCategoryResponse[]

export type TicketingEvent = {
  id: number
  name: string
  start: Date
  end: Date
  purchaseStart: Date
  purchaseEnd: Date
  checkinStart: Date
  checkinEnd: Date
  pictureUrl: string
  template: string
  description: string
  local: string
  accessCategories: TicketingEventAccessCategory[]
}

type TicketingEventResponse = {
  id: number
  name: string
  start: string
  purchase_start: string
  purchase_end: string
  checkin_start: string
  checkin_end: string
  end: string
  picture_url: string
  template: 'image' | 'details'
  description: string
  local: string
  access_categories: TicketingEventAccessCategoryResponse[]
}

export type TicketingEventsResponse = TicketingEventResponse[]

type TicketResponse = {
  id: number
  event_access_category_id: number
  event_id: number
  member_id: number
  movement_paid: boolean
  used: boolean
  price: number
  uuid: string
}

export type TicketsResponse = TicketResponse[]

export type Ticket = {
  id: number
  uuid: string
  price: number
  eventId: number
  eventAccessCategoryId: number
  paid: boolean
  used: boolean
}

export type PurchaseResponseData = {
  value: number
  id: string
  method: string
  entity: string
  reference: string
  status: 'pending' | 'error'
}

export type PurchaseState = {
  type: 'error' | 'pending' | 'success'
  data?: PurchaseResponseData
  errorMessage?: string
}

export type TicketPurchaseMovementResponseData = {
  date: string
  value: number
}

export type TicketPurchaseMovement = {
  date: Date
  value: number
}

export type TicketPurchasePaymentResponseData = {
  checked: boolean
  date: string
  value: number
  method: string
}

export type TicketPurchasePayment = {
  checked: boolean
  date: Date
  value: number
  method: PaymentMethod
}

export type TicketPaymentData = {
  movement: TicketPurchasePayment
  payments: PaymentResponse
}