import { Card, CardContent, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import './MembershipCard.css'
import React, { useContext, useEffect, useState } from 'react'

import QRCode from 'qrcode'

import { Member } from '../../types/member'
import AppStateContext from '../../contexts/AppStateContext'
import MemberSituationContext from '../../contexts/MemberSituationContext'

type Props = {
  member: Member
}

function MembershipCard({ member }: Props) {
  const { iconFile, myClubUrl, myClubStyles } = useContext(AppStateContext)
  const { situation } = useContext(MemberSituationContext)

  const [memberQrCode, setMemberQrCode] = useState<string>()

  const generateQrCode = async (content: string) => {
    const qrCode = await QRCode.toDataURL(content)

    setMemberQrCode(qrCode)
  }

  useEffect(() => {
    generateQrCode(`${myClubUrl}?cmmemberid=${member.uuid}`)
  }, [member])

  if (!member) {
    return <></>
  }

  const { periods, pendingTotal } = situation
  const pendingPeriods = periods.filter((period) => period.status === 'MISSING')
  const isRegularized = pendingTotal === 0 && !pendingPeriods.length

  const {primaryColor, secondaryColor } = myClubStyles || {}

  const cardBackground = (primaryColor && secondaryColor) ? `linear-gradient(170deg, ${primaryColor}, ${secondaryColor})` : null;

  return (
    <Card sx={{ minWidth: 275, background: cardBackground }} className="membership-card-wrapper">
      <CardContent>
        <div className="member-card-qrcode">
          {memberQrCode ? <img src={memberQrCode} /> : null}
        </div>

        <div>
          <div className='flex-horizontal justify-end align-center' style={{ gap: '8px' }}>
            <div className="member-card-member-text-data">
              <div className="member-card-number">Sócio Nº {member.number}</div>
              <div className="member-card-name">{member.name}</div>
            </div>

            <div
              className="flex-vertical justify-start"
              style={{ gap: '8px' }}
            >
              {isRegularized ? <CheckCircleIcon
                color="success"
                style={{ fontSize: '48px' }}
              ></CheckCircleIcon> : <ErrorIcon color="error" style={{ fontSize: '40px' }}></ErrorIcon>
              }
            </div>
          </div>
        </div>

        <div className="member-card-icon">
          <img src={iconFile} />
        </div>

        <div className="member-card-picture">
          <div
            className="background-covered"
            style={{
              backgroundImage: `url(${member.picture_url})`,
            }}
          ></div>
        </div>
      </CardContent>
    </Card>
  )
}

export default MembershipCard
