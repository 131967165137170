import { Autocomplete, Box, TextField, TextFieldVariants } from '@mui/material'
import { countries } from '../../constants'
import React, { BaseSyntheticEvent, useEffect, useState } from 'react'

import { Country } from '../../types'

type Props = {
  disabled?: boolean
  error?: boolean
  id?: string
  label?: string
  onChange?: (value: string | null) => void
  variant?: TextFieldVariants
  value?: string
  defaultCountry?: string
  preferredCountries?: string[]
}

export default function CountrySelect(props: Props) {
  const [innerValue, setInnerValue] = useState<Country | null>(countries[0])
  const [countriesList, setCountriesList] = useState<Country[]>(
    countries as Country[]
  )

  useEffect(() => {
    if (!props.preferredCountries) {
      return
    }

    const countriesCopy = [...countries]
    countriesCopy.sort((a, b) =>
      props.preferredCountries?.includes(a.code)
        ? -1
        : props.preferredCountries?.includes(b.code)
        ? 1
        : 0
    )

    setCountriesList(countriesCopy)
  }, [props.preferredCountries])

  useEffect(() => {
    if (!props.defaultCountry) {
      return
    }

    if (!props.value) {
      const country = countries.find((c) => c.code === props.defaultCountry)
      setInnerValue(country || null)
    }
  }, [])

  useEffect(() => {
    const country = countriesList.find(
      (country) => country.code === props.value
    )

    if (country) {
      setInnerValue(country)
    }
  }, [])

  useEffect(() => {
    props.onChange && props.onChange(innerValue ? innerValue.code : null)
  }, [innerValue])

  const handleInputChange = (event: BaseSyntheticEvent) => {
    const inputValue = event?.target?.value

    if (!inputValue || typeof inputValue !== 'string') {
      return
    }

    const countryType = countriesList.find(
      (country) => country.code.toLowerCase() === inputValue.toLowerCase()
    )

    if (countryType) {
      setInnerValue(countryType)
    }
  }

  const handleChange = (event: any, newValue: Country | null) => {
    setInnerValue(newValue)
  }

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      options={countriesList}
      autoHighlight
      value={innerValue}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          style={{ width: '100%' }}
          {...params}
          label="Selecione um país"
          inputProps={{
            ...params.inputProps,
          }}
          variant={props.variant}
          error={props.error}
        />
      )}
      disabled={props.disabled}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  )
}
