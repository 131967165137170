import React from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { MemberSituationData } from '../../types'

type Props = {
  situation: MemberSituationData
}

function MembershipStatusCard({ situation }: Props) {
  const { periods, pendingTotal } = situation

  const pendingPeriods = periods.filter((period) => period.status === 'MISSING')
  const isRegularized = pendingTotal === 0 && !pendingPeriods.length

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
      }}
    >
      <CardContent>
        {isRegularized ? (
          <>
            <Typography
              variant='body2'
              color="text.secondary"
              gutterBottom
            >
              Estado de quotização:
            </Typography>
            <div
              className="flex-horizontal flex-centered"
              style={{ gap: '8px' }}
            >
              <CheckCircleIcon
                color="success"
                style={{ fontSize: '36px' }}
              ></CheckCircleIcon>
              <Typography
                variant="h6"
                component="span"
                style={{ fontSize: '24px' }}
              >
                Regularizado
              </Typography>
            </div>
          </>
        ) : (
          <>
            <Typography
              sx={{ fontSize: 18 }}
              color="text.secondary"
              gutterBottom
            >
              Tem quotas em atraso no valor de
            </Typography>
            <div
              className="flex-horizontal flex-centered"
              style={{ gap: '8px' }}
            >
              <ErrorIcon color="error" style={{ fontSize: '40px' }}></ErrorIcon>
              <Typography
                variant="h5"
                component="span"
                style={{ fontSize: '36px' }}
              >
                {pendingTotal} €
              </Typography>
            </div>
          </>
        )}
      </CardContent>

      {pendingPeriods?.length ? <CardActions>
        <Button size="small" href="/#/membership-details">
          Regularizar quotas
        </Button>

      </CardActions> : null}
    </Card>
  )
}

export default MembershipStatusCard
