import { useContext, useEffect, useState } from 'react'
import AppStateContext from '../../contexts/AppStateContext'
import { PaymentMethod, PurchaseResponseData, PurchaseState } from '../../types'
import { Card, CircularProgress, Container, Typography } from '@mui/material'
import React from 'react'

import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

import './PurchaseStateSummary.scss'


const IconImage = function (url: string) {
  return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={url} />
}

const Mbway = () => IconImage('logos/mbway.png')
const Multibanco = () => IconImage('logos/multibanco.png')
const BackTransfer = () => IconImage('logos/banktransfer.png')

type PaymentCheckResponseData = {
  status: 'paid' | 'pending'
}

const fetchCheckPayment = async (apiBaseUrl: string, paymentId: string) => {
  const response = await fetch(`${apiBaseUrl}/payments/${paymentId}/check`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })

  return response.json() as Promise<PaymentCheckResponseData>
}

type Props = {
  state: PurchaseState
}

function PurchaseStateSummary({ state }: Props) {
  const { apiBaseUrl } = useContext(AppStateContext)
  const [currentPurchaseState, setCurrentPurchaseState] =
    useState<PurchaseState>(state)

  useEffect(() => {
    if (!apiBaseUrl || !state.data) return

    if (state.type === 'pending' && state.data.method === PaymentMethod.MBWAY) {
      const interval = setInterval(async () => {
        try {
          const response = await fetchCheckPayment(
            apiBaseUrl,
            (state.data as PurchaseResponseData).id as string
          )

          if (response.status === 'paid') {
            clearInterval(interval)
            setCurrentPurchaseState({
              ...currentPurchaseState,
              type: 'success',
            })
          }
        } catch (error) {
          setCurrentPurchaseState({
            type: 'error',
            errorMessage:
              'Ocorreu um erro ao verificar o pagamento, mas tal não significa que não tenha sido efetuado. Por favor, contacte o suporte se não receberes confirmação da tua compra.',
          })
        }
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [state])

  return (
    <Container className="PurchaseStateSummary">
      {currentPurchaseState?.type === 'success' ? (
        <div className="PurchaseStateSummary__status-message">
          <CheckCircle
            fontSize="large"
            style={{ color: 'rgb(0, 200, 151)' }}
          ></CheckCircle>
          <Typography variant="subtitle1">Compra concluída</Typography>
        </div>
      ) : currentPurchaseState?.type === 'error' ? (
        <div className="PurchaseStateSummary__status-message">
          <ErrorIcon fontSize="large" color="error"></ErrorIcon>
          <Typography variant="subtitle2">
            {currentPurchaseState.errorMessage}
          </Typography>
        </div>
      ) : currentPurchaseState?.type === 'pending' ? (
        currentPurchaseState.data?.method === PaymentMethod.MBWAY ? (
          <>
            <Typography variant="body1">
              Aceita agora, o pedido de pagamento na app MBWay associada ao teu
              número de telemóvel.
              <br />A aguardar confirmação de pagamento.
              <br />Pode demorar alguns minutos até a confirmação ser recebida. Não te preocupes, o pagamento será processado mesmo que feches esta janela.
            </Typography>
            <CircularProgress />
          </>
        ) : currentPurchaseState.data?.method === PaymentMethod.MB_REFERENCE ? (
          <>
            <Card variant="outlined" className="mbreference-card">
              <div className="mbreference-icon-container">
                <Multibanco />
              </div>

              <div className="mbreference-data">
                <div>
                  <Typography fontWeight={600} variant="subtitle1">
                    Entidade
                  </Typography>
                  <span>{currentPurchaseState.data.entity}</span>
                </div>
                <div>
                  <Typography fontWeight={600} variant="subtitle1">
                    Referência
                  </Typography>
                  <span> {currentPurchaseState.data.reference}</span>
                </div>
                <div>
                  <Typography fontWeight={600} variant="subtitle1">
                    Valor
                  </Typography>
                  <span>{currentPurchaseState.data.value}€</span>
                </div>
              </div>
            </Card>
            <Typography variant="body2">
              Guarda estes dados para proceder ao pagamento. Podes depois,
              encerrar esta janela. Os teus items serão atualizados assim que o
              pagamento for confirmado
            </Typography>
          </>
        ) : (
          <div className="PurchaseStateSummary__status-message">
            <CheckCircle fontSize="large" color="warning"></CheckCircle>
            <Typography variant="body2">
              Os teus items serão atualizados assim que o pagamento for
              confirmado
            </Typography>
          </div>
        )
      ) : (
        <>
          <ErrorIcon fontSize="large" color="error"></ErrorIcon>
          <Typography variant="subtitle1">
            Lamentamos, ocorreu um erro desconhecido ao efetuar compra. Por
            favor contacte o suporte.
          </Typography>
        </>
      )}
    </Container>
  )
}

export default PurchaseStateSummary
