import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import TopBar from './TopBar'
import { CircularProgress, Container } from '@mui/material'
import { MemberSituationContextData, MemberSituationData } from '../types'
import AppStateContext from '../contexts/AppStateContext'
import { Outlet, useNavigate } from 'react-router-dom'
import { Period } from '../types/pending-period'

import MemberSituationContext from '../contexts/MemberSituationContext'
import useApi from '../hooks/useApi'

type PaymentInfoResponse = {
  member: {
    name: string
    msisdn: string
    email: string
  }
  periods: Period[]
}

function MainPage() {
  const { apiBaseUrl, member } = useContext(AppStateContext)
  const api = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [memberSituation, setMemberSituation] = useState<MemberSituationData>({
    periods: [] as Period[],
    pendingTotal: 0,
  })

  const navigate = useNavigate()

  const getData = useCallback(async () => {
    if (!api) {
      return
    }

    setIsLoading(true)

    const response = await api.get<PaymentInfoResponse>('payments/info')
    const paymentInfo = response.data

    const periods = paymentInfo.periods || []

    setMemberSituation({
      periods,
      pendingTotal: periods
        .filter((period) => period.status === 'MISSING' || period.status === 'PENDING')
        .reduce((prev, curr) => prev + curr.value, 0),
    })

    setIsLoading(false)
  }, [api])

  const memberSituationContext = useMemo<MemberSituationContextData>(() => ({
    situation: memberSituation,
    setSituation: (memberSituation: MemberSituationData) => setMemberSituation(memberSituation),
    updateSituation: () => getData(),
  }), [getData, memberSituation])

  console.log('memberSituation', memberSituation)
  console.log('memberSituationContext', memberSituationContext)
  console.log('isMemberRegularized', memberSituation.pendingTotal === 0)

  useEffect(() => {
    if (!apiBaseUrl || !member) {
      return
    }

    getData()
  }, [member?.uuid, getData])

  useEffect(() => {
    if (!member) {
      navigate('/login')
    }
  }, [])

  if (isLoading) {
    return (
      <Container
        style={{ height: '100%', width: '100%' }}
        className="flex-horizontal flex-centered"
      >
        <CircularProgress style={{ color: 'white' }} />
      </Container>
    )
  }

  return (
    <div className="MainPage">
      <TopBar />

      <Container
        className="flex-1"
        style={{
          paddingTop: '16px',
          paddingBottom: '16px',
          flex: 1,
          overflowY: 'auto',
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MemberSituationContext.Provider value={memberSituationContext}>
          <Outlet></Outlet>
        </MemberSituationContext.Provider>
      </Container>
    </div>
  )
}
export default MainPage
