import React, { useContext } from 'react'
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Tooltip,
  Avatar,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'

import AppStateContext from '../contexts/AppStateContext'

const fetchLogout = async (apiBaseUrl: string) => {
  const response = await fetch(`${apiBaseUrl}/logout`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  })

  if (!response.ok) {
    throw new Error(`Error! status: ${response.status}`)
  }

  if (response.status === 200) {
    return true
  }
}

function TopBar() {
  const navigate = useNavigate()

  const appState = useContext(AppStateContext)
  const { apiBaseUrl, appName, iconFile, member, logout, myClubStyles } = appState

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const goToDashboard = () => {
    navigate('/dashboard')
    handleCloseNavMenu()
  }

  const goToMemberSituation = () => {
    navigate('/membership-details')
    handleCloseNavMenu()
  }

  const goToTicketOffice = () => {
    navigate('/ticket-office')
    handleCloseNavMenu()
  }

  const goToProfile = () => {
    navigate('/profile')
    handleCloseUserMenu()
  }

  const logoutApp = () => {
    if (!apiBaseUrl) {
      return
    }

    handleCloseUserMenu()

    fetchLogout(apiBaseUrl)
      .then(() => {
        logout()
        navigate('/login')
      })
      .catch(() => { })
  }

  if (!member) {
    return <></>
  }

  return (
    <AppBar position="static" style={myClubStyles?.primaryColor ? { backgroundColor: myClubStyles.primaryColor } : {}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Container
            sx={{
              mr: 2,
              display: { xs: 'none', sm: 'flex' },
              textDecoration: 'none',
              padding: '0px !important',
              width: 'auto !important',
            }}
          >
            <img height="40px" src={iconFile} />
          </Container>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <MenuItem onClick={goToDashboard}>
                <Typography textAlign="center">Início</Typography>
              </MenuItem>
              <MenuItem onClick={goToMemberSituation}>
                <Typography textAlign="center">Quotizações</Typography>
              </MenuItem>
              <MenuItem onClick={goToTicketOffice}>
                <Typography textAlign="center">Bilheteira</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Container
            sx={{
              mr: 2,
              display: { xs: 'flex', sm: 'none' },
              flexGrow: 1,
              fontFamily: 'Oswald',
              fontSize: '16px !important',
              fontWeight: 400,
              color: 'inherit',
              textDecoration: 'none',
              width: '100%',
              margin: '0 !important',
              padding: '0 !important',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <img height="40px" src={iconFile} />
            <span>{appName}</span>
          </Container>
          <Box className="gap-8" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
            <Button
              onClick={goToDashboard}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Início
            </Button>
            <Button
              onClick={goToMemberSituation}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Quotizações
            </Button>
            <Button
              onClick={goToTicketOffice}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Bilheteira
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: '16px' }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={member.name} src={member.picture_url} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={goToProfile}>
                <Typography textAlign="center">Os meus dados</Typography>
              </MenuItem>
              <MenuItem onClick={logoutApp}>
                <Typography textAlign="center">Sair</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default TopBar
