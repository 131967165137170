import React, { useContext } from 'react'

import {
  Card,
  CardContent,
  Typography,
} from '@mui/material'

import MemberSituationContext from '../contexts/MemberSituationContext'
import MembershipStatusCard from './MembershipStatusCard/MembershipStatusCard'
import AppStateContext from '../contexts/AppStateContext'
import NextEventCard from './NextEventCard/NextEventCard'
import MembershipCard from './MembershipCard/MembershipCard'

function MemberDashboard() {
  const state = useContext(AppStateContext)

  // 

  const { member } = state
  const memberSituationContext = useContext(MemberSituationContext)

  if (!member) {
    return <></>
  }

  return (
    <div className='MemberDashboard'>
      <MembershipCard member={member}></MembershipCard>
      <NextEventCard />
    </div>
  )
}

export default MemberDashboard
