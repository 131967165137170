import { matchIsValidTel } from 'mui-tel-input'

export const isValidEmail = (email?: string) =>
  email &&
  email.trim().length > 0 &&
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(email.trim())

export const isValidMsisdn = (msisdn?: string) =>
  msisdn && matchIsValidTel(msisdn)

export const isValidPassword = (password?: string) =>
  password &&
  password.length >= 8 &&
  /[a-z]+/.test(password) &&
  /[0-9]+/.test(password)
