import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './components/App/App'
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom'
import ErrorPage from './components/ErrorPage/ErrorPage'
import AccountCreate from './components/AccountCreate/AccountCreate'
import Login from './components/Login'
import MainPage from './components/MainPage'
import MemberDashboard from './components/MemberDashboard'
import Profile from './components/Profile/Profile'
import MembershipPeriods from './components/MembershipPeriods/MembershipPeriods'
import TicketOffice from './components/TicketOffice/TicketOffice'

import momentTimezone from 'moment-timezone'
import 'moment/locale/pt-br';

const defaultLocale = 'pt-br'

momentTimezone.tz.setDefault('Europe/Lisbon')
momentTimezone.locale(defaultLocale)

const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <AccountCreate />,
      },
      {
        path: '/',
        element: <MainPage />,
        children: [
          {
            path: '/',
            element: <MemberDashboard />,
          },
          {
            path: '/dashboard',
            element: <MemberDashboard />,
          },
          {
            path: '/membership-details',
            element: <MembershipPeriods />,
          },
          {
            path: '/ticket-office',
            element: <TicketOffice />,
          },
          {
            path: '/profile',
            element: <Profile />,
          },
        ],
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as Element)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
