import { useContext, useMemo } from "react";
import AppStateContext from "../contexts/AppStateContext";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const useApi = () => {
  const { apiBaseUrl } = useContext(AppStateContext);
  const navigate = useNavigate();

  if (!apiBaseUrl) throw Error('API NOT DEFINED');

  const axiosClient = useMemo(() => axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true,
  }), [apiBaseUrl]);

  axiosClient.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error: AxiosError) => {
      const {status} = error.response!;
      switch (status) {
        case 400:
          console.log(error.response);
          break;
        case 401:
          console.log("Unauthorized");
          navigate('/login', { replace: true })
          break;
        case 404:
          console.log(error.response?.status);
          break;
        case 500:
          console.log("server error");
          break;
        default:
          console.log("an unknown error occurred");
          break;
      }
      return Promise.reject(error);
    }
  );

  return axiosClient;
};

export default useApi;