import React, { useContext, useEffect, useState } from 'react'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import AppStateContext from '../../contexts/AppStateContext'

import './TicketOffice.scss'
import {
  TicketingEvent,
  TicketingEventsResponse,
} from '../../types'
import TicketingEventCard from '../TicketingEventCard/TicketingEventCard'
import { Skeleton, Snackbar, Typography } from '@mui/material'
import useApi from '../../hooks/useApi'

const sortByDate = (a: TicketingEvent, b: TicketingEvent) => {
  return a.start.getTime() - b.start.getTime()
}

type SnackbarData = {
  isVisible: boolean
  message: string
  type: 'error' | 'success'
}

function TicketOffice() {
  const api = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData>()

  const [ticketingEvents, setTicketingEvents] = useState<TicketingEvent[]>()

  const getTicketingEvents = async () => {
    setIsLoading(true)

    try {
      const response = await api.get<TicketingEventsResponse>(`ticketing/events`)

      const events = response.data.map((event) => ({
        id: event.id,
        name: event.name,
        start: new Date(event.start),
        end: new Date(event.end),
        purchaseStart: new Date(event.purchase_start),
        purchaseEnd: new Date(event.purchase_end),
        checkinStart: new Date(event.checkin_start),
        checkinEnd: new Date(event.checkin_end),
        pictureUrl: event.picture_url,
        template: event.template ? event.template : 'details',
        description: event.description,
        local: event.local,
        accessCategories: (event.access_categories || []).map((category) => ({
          id: category.id,
          title: category.title,
          memberExclusive: category.member_exclusive,
          description: category.description,
          price: category.price,
          capacity: category.capacity,
          enabled: category.enabled,
        })),
      })).sort(sortByDate)

      setTicketingEvents(events)
    } catch (error) {
      console.error(error)
      setSnackbarData({
        isVisible: true,
        message:
          'Ocorreu um erro ao obter eventos. Por favor contacte o suporte.',
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTicketingEvents();
  }, [])

  const handleSnackbarClose = () => {
    setSnackbarData(undefined)
  }

  return (
    <>
      <div className="ticket-office__ticketing-event-container">
        {isLoading && <div className='ticket-office__ticketing-event-loading'>
          <Skeleton variant="rounded" />
          <Skeleton variant="rounded" />
          <Typography textAlign="center">A carregar eventos...</Typography>
        </div>}

        {!isLoading && !ticketingEvents?.length && <div className="ticket-office__ticketing-event-empty">
          <Typography variant="h5" textAlign="center">Não existem eventos próximos</Typography>
          <EventBusyIcon style={{ fontSize: 100 }} /></div>
        }

        {!isLoading && ticketingEvents?.map((event, index) => (
          <TicketingEventCard
            event={event}
            key={event.id}
          />
        ))}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarData?.isVisible}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarData?.message}
        className={'snackbar snackbar--' + snackbarData?.type}
      />
    </>
  )
}

export default React.memo(TicketOffice)
