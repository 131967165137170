import { createContext } from 'react'
import { MyClubState, MyClubStateContext } from '../types'
import { ErrorType } from '../types/error-type'

export default createContext<MyClubStateContext>({
  authenticated: false,
  isFetchingCritical: false,
  patchState: () => {},
  setState: () => {},
  logout: () => {},
  discardError: () => {},
  setError: (error: ErrorType) => {},
})
