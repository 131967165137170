import React, { useCallback, useContext, useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import AppStateContext from '../../contexts/AppStateContext'

import './Profile.css'
import MembershipCard from '../MembershipCard/MembershipCard'
import { Member } from '../../types/member'
import { MuiTelInput } from 'mui-tel-input'
import { DEFAULT_COUNTRY, PREFERED_COUNTRIES } from '../../constants'
import { isValidEmail, isValidMsisdn } from '../../validators'
import CountrySelect from '../CountrySelect/CountrySelect'

const fetchSaveMember = async (apiBaseUrl: string, member: Member) => {
  const response = await fetch(`${apiBaseUrl}/me`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(member),
    credentials: 'include',
  })

  if (response.status === 200) {
    return response.json()
  }

  throw 'Error'
}

type MemberFormGroupType = 'address' | 'contacts'
type SnackbarData = {
  isVisible: boolean
  message: string
  type: 'error' | 'success'
}

function Profile() {
  const { apiBaseUrl, member, patchState } = useContext(AppStateContext)

  const [address, setAddress] = useState<string | undefined>('')
  const [locality, setLocality] = useState<string | undefined>('')
  const [postalCode, setPostalCode] = useState<string | undefined>('')
  const [email, setEmail] = useState<string | undefined>('')
  const [msisdn, setMsisdn] = useState<string | undefined>('')
  const [country, setCountry] = useState<string | undefined>('')

  const [snackbarData, setSnackbarData] = useState<SnackbarData>()
  const [isSaving, setIsSaving] = useState(false)

  const [editingGroup, setEditingGroup] = useState<MemberFormGroupType | null>(
    null
  )

  useEffect(() => fillMemberData(member), [member])

  const fillMemberData = (member?: Member) => {
    setAddress(member?.address)
    setLocality(member?.locality)
    setPostalCode(member?.postal_code)
    setCountry(member?.country)
    setEmail(member?.email)
    setMsisdn(member?.msisdn)
  }

  if (!member) {
    return <></>
  }

  const validAddress = isValidEmail(email)
  const validAddressField = address === undefined || validAddress

  const validLocality = locality?.length
  const validLocalityField = locality === undefined || validLocality

  const validPostalCode = postalCode?.length
  const validPostalCodeField = postalCode === undefined || validPostalCode

  const validCountry = country?.length
  const validCountryField = country === undefined || validCountry

  const validEmail = isValidEmail(email)
  const validEmailField = email === undefined || validEmail

  const validMsisdn = isValidMsisdn(msisdn)
  const validMsisdnField = msisdn === undefined || validMsisdn

  const editGroup = (group: MemberFormGroupType) => {
    setEditingGroup(group)
  }

  const saveGroup = async (group: MemberFormGroupType) => {
    if (!apiBaseUrl || !member) {
      return
    }

    if (group === 'address') {
      if (
        !validAddress ||
        !validLocality ||
        !validPostalCode ||
        !validCountry
      ) {
        return
      }
    }

    if (group === 'contacts') {
      if (!validEmail || !validMsisdn) {
        return
      }
    }

    setIsSaving(true)

    const data = {
      ...member,
      ...(group === 'address'
        ? {
            address,
            locality,
            postal_code: postalCode,
            country,
          }
        : group === 'contacts'
        ? {
            email,
            msisdn: msisdn?.replace(/\s/g, ''),
          }
        : {}),
    }

    const updatedMember = await fetchSaveMember(apiBaseUrl, data)

    if (updatedMember) {
      setSnackbarData({
        isVisible: true,
        message: 'Os seus dados foram salvos com sucesso',
        type: 'success',
      })

      patchState({
        member: updatedMember,
      })
    } else {
      setSnackbarData({
        isVisible: true,
        message:
          'Ocorreu um erro ao salvar os seus dados. Por favor tente novamente',
        type: 'error',
      })
    }

    setIsSaving(false)
    setEditingGroup(null)
  }

  const discardGroup = (group: MemberFormGroupType) => {
    fillMemberData(member)
    setEditingGroup(null)
  }

  const handleSnackbarClose = () => {
    setSnackbarData(undefined)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarData?.isVisible}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarData?.message}
        className={'snackbar snackbar--' + snackbarData?.type}
      />

      <Container className="profile-wrapper">
        <MembershipCard member={member}></MembershipCard>

        <Card sx={{ minWidth: 275 }}>
          <CardContent className="profile-form-group profile-address">
            <Typography variant="h5" textAlign="left" marginBottom="16px">
              Morada
            </Typography>

            <TextField
              id="standard-basic"
              label="Rua"
              variant="standard"
              value={address}
              onChange={(evt) => setAddress(evt.target.value)}
              error={!validAddressField}
              disabled={isSaving || editingGroup !== 'address'}
            />

            <TextField
              id="standard-basic"
              label="Localidade"
              variant="standard"
              value={locality}
              onChange={(evt) => setLocality(evt.target.value)}
              error={!validLocalityField}
              disabled={isSaving || editingGroup !== 'address'}
            />

            <TextField
              id="standard-basic"
              label="Código Postal"
              variant="standard"
              value={postalCode}
              onChange={(evt) => setPostalCode(evt.target.value)}
              error={!validPostalCodeField}
              disabled={isSaving || editingGroup !== 'address'}
            />

            <CountrySelect
              id="standard-basic"
              label="País"
              variant="standard"
              value={country}
              defaultCountry={DEFAULT_COUNTRY}
              preferredCountries={PREFERED_COUNTRIES}
              onChange={(value: string | null) => setCountry(value || '')}
              error={!validCountryField}
              disabled={isSaving || editingGroup !== 'address'}
            />
          </CardContent>

          <CardActions>
            {editingGroup === null && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => editGroup('address')}
              >
                Editar
              </Button>
            )}

            {editingGroup === 'address' && (
              <>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => saveGroup('address')}
                    startIcon={<SaveIcon />}
                  >
                    Gravar
                  </Button>

                  {isSaving && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => discardGroup('address')}
                  startIcon={<CloseIcon />}
                >
                  Cancelar alterações
                </Button>
              </>
            )}
          </CardActions>
        </Card>

        <Card sx={{ minWidth: 275 }}>
          <CardContent className="profile-form-group profile-contacts">
            <Typography variant="h5" textAlign="left" marginBottom="16px">
              Contactos
            </Typography>

            <TextField
              id="standard-basic"
              label="E-mail"
              variant="standard"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              error={!validEmailField}
              disabled={isSaving || editingGroup !== 'contacts'}
            />

            <MuiTelInput
              variant="standard"
              defaultCountry={DEFAULT_COUNTRY}
              preferredCountries={PREFERED_COUNTRIES}
              forceCallingCode={true}
              focusOnSelectCountry={true}
              value={msisdn}
              onChange={(value) => setMsisdn(value)}
              error={!validMsisdnField}
              disabled={isSaving || editingGroup !== 'contacts'}
            />
          </CardContent>

          <CardActions>
            {editingGroup === null && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => editGroup('contacts')}
              >
                Editar
              </Button>
            )}

            {editingGroup === 'contacts' && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => saveGroup('contacts')}
                  startIcon={<SaveIcon />}
                >
                  Gravar
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => discardGroup('contacts')}
                  startIcon={<CloseIcon />}
                >
                  Cancelar alterações
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Container>
    </>
  )
}
export default Profile
