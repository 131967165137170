import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import HourglassEmpty from '@mui/icons-material/HourglassEmpty'
import React, { useState, useContext, useMemo } from 'react'
import { Period } from '../../types/pending-period'

import './PaymentInfo.css'
import Purchase, { PurchaseItem } from '../Purchase/Purchase'
import useApi from '../../hooks/useApi'
import MemberSituationContext from '../../contexts/MemberSituationContext'

type Props = {
  periods: Period[]
  value: number
}

export default function PaymentInfo({ periods, value }: Props) {
  const api = useApi()

  const { updateSituation } = useContext(MemberSituationContext)

  const [membershipPaymentDialogVisible, setMembershipPaymentDialogVisible] = useState(false)
  const [purchaseDone, setPurchaseDone] = useState(false)

  const processPrePayment = async () => {
    if (!api || !periods?.length) return;

    const response = await api.post('/membership/pay', periods.map(period => period.id));

    return response.data;
  }

  const handleCancelPurchase = () => {
    setMembershipPaymentDialogVisible(false)
  }

  const handlePurchaseComplete = () => {
    setPurchaseDone(true)
  }

  const handlePurchaseDone = () => {
    setMembershipPaymentDialogVisible(false)
    updateSituation()
  }

  const itemsToPurchase: PurchaseItem[] = useMemo(() => {
    return periods.map((period) => ({
      id: period.id,
      title: period.name,
      price: period.value,
    }));
  }, [periods])

  const hasPendingPayments = periods.some((period) => period.status === 'PENDING')

  return (
    <div className="payment-info-wrapper">
      {hasPendingPayments ? (
        <Box>
          <Typography variant="h5" gutterBottom>
            <HourglassEmpty color='warning' />
            <div>Tens pelo menos um pagamento pendente</div>
          </Typography>
          <Typography gutterBottom>
            Se já pagaste, mas não vires o teu pagamento refletido aqui, por favor contacta-nos.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography gutterBottom>
            Podes agora pagar as tuas quotas através de MB Way ou Referência Multibanco.
          </Typography>

          <Typography variant="h5" gutterBottom>
            <Button
              variant="contained"
              onClick={() => setMembershipPaymentDialogVisible(true)}
              className="submit-button"
            >
              Pagar agora
            </Button>
          </Typography>
        </Box>
      )}

      <Dialog open={membershipPaymentDialogVisible}>
        <DialogTitle>Pagar quotas</DialogTitle>
        <DialogContent>
          <Purchase
            items={itemsToPurchase}
            onProceed={() => processPrePayment()}
            onComplete={() => handlePurchaseComplete()}
          ></Purchase>
        </DialogContent>
        <DialogActions>
          {purchaseDone && <Button onClick={() => handlePurchaseDone()}>Fechar</Button>}
          {!purchaseDone && (
            <Button onClick={() => handleCancelPurchase()}>Cancelar</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
