import { useContext, useEffect, useRef, useState } from 'react'
import AppStateContext from '../../contexts/AppStateContext'
import {
  Button,
  CircularProgress,
  Container,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from '@mui/material'
import React from 'react'

import './Purchase.scss'
import PurchaseStateSummary from '../PurchaseStateSummary/PurchaseStateSummary'
import { PaymentMethod, PurchaseState } from '../../types'


type PurchaseResponseData = {
  value: number
  id: string
  method: string
  entity: string
  reference: string
  status: 'pending' | 'error'
}

const IconImage = function (url: string) {
  return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={url} />
}

const Mbway = () => IconImage('logos/mbway.png')
const Multibanco = () => IconImage('logos/multibanco.png')
const BackTransfer = () => IconImage('logos/banktransfer.png')

const fetchPayItems = async (
  apiBaseUrl: string,
  value: number,
  itemsIds: string[],
  paymentMethod: string
) => {
  const response = await fetch(`${apiBaseUrl}/payments`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      items: itemsIds,
      payment_method: paymentMethod,
      value,
    }),
    credentials: 'include',
  })

  return response.json() as Promise<PurchaseResponseData>
}

export type PurchaseItem = {
  title: string
  price: number
}

type Props = {
  items: PurchaseItem[]
  onProceed: () => Promise<string[]>
  onComplete: () => void
}

function Purchase({ items, onComplete, onProceed }: Props) {
  const { apiBaseUrl } = useContext(AppStateContext)

  const stepperElement = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [purchaseState, setPurchaseState] = useState<PurchaseState>()
  const [activeStep, setActiveStep] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()

  const requestPay = async (
    apiBaseUrl: string,
    value: number,
    itemsIds: string[],
    paymentMethod: string
  ) => {
    try {
      const data = await fetchPayItems(
        apiBaseUrl,
        value,
        itemsIds,
        paymentMethod
      )

      setPurchaseState({
        type: (data as PurchaseResponseData).status,
        data: data as PurchaseResponseData,
      })

      onComplete()
    } catch (error) {
      throw new Error(
        'Lamentamos, mas ocorreu um erro ao efetuar o pagamento. Por favor contacte o suporte se o problema persistir'
      )
    }
  }

  const canProceedToStep = (step: number) => {
    if (isLoading || purchaseState?.data || purchaseState?.errorMessage)
      return false

    switch (step) {
      case 0:
        return true
      case 1:
        return items.length > 0
      case 2:
        return items.length > 0 && Boolean(paymentMethod)
      default:
        return false
    }
  }

  const launchPayment = async () => {
    if (!apiBaseUrl || !items || !paymentMethod || !onProceed) {
      return
    }

    setIsLoading(true)

    try {
      const itemsIds = await onProceed()

      const value = items.reduce((acc, item) => acc + item.price, 0)

      await requestPay(apiBaseUrl, value, itemsIds, paymentMethod)
    } catch (error) {
      console.error(error)

      setPurchaseState({
        type: 'error',
        errorMessage: (error as Error).message,
      })
    }

    setActiveStep(3)
    setIsLoading(false)
  }

  useEffect(() => {
    const stepContainers = Array.from(
      stepperElement.current?.childNodes || []
    ).filter((child) => (child as Element).classList.contains('MuiStep-root'))

    ;(stepContainers[activeStep] as Element)?.scrollIntoView(false)
  }, [activeStep])

  return (
    <Container className="Purchase">
      {isLoading ? (
        <Container className="loading-container">
          <CircularProgress />
          <div>
            Por favor aguarde. Estamos a processar o pagamento. Não encerre esta
            janela...
          </div>
        </Container>
      ) : (
        <Stepper
          ref={stepperElement}
          activeStep={activeStep}
          nonLinear
          orientation="vertical"
        >
          <Step key="0">
            <StepButton
              color="inherit"
              onClick={() => setActiveStep(0)}
              disabled={!canProceedToStep(0)}
            >
              Reveja o(s) item(s) a pagar
            </StepButton>
            <StepContent>
              {items.map((item, index) => (
                <div key={index}>
                  {item.title} ({item.price}€)
                </div>
              ))}
              <Button
                className='filled'
                onClick={() => setActiveStep(1)}
                variant="contained"
                disableElevation
              >
                Seguinte
              </Button>
            </StepContent>
          </Step>

          <Step key="1">
            <StepButton
              color="inherit"
              onClick={() => setActiveStep(1)}
              disabled={!canProceedToStep(1)}
            >
              Selecione a forma de pagamento
            </StepButton>
            <StepContent>
              <div className="flex-horizontal gap-16">
                <Button
                  className={`Purchase__payment-method-button ${
                    paymentMethod === PaymentMethod.MBWAY &&
                    'Purchase__payment-method-button--selected'
                  }`}
                  onClick={() => setPaymentMethod(PaymentMethod.MBWAY)}
                  startIcon={<Mbway />}
                  variant="outlined"
                ></Button>

                <Button
                  className={`Purchase__payment-method-button ${
                    paymentMethod === PaymentMethod.MB_REFERENCE &&
                    'Purchase__payment-method-button--selected'
                  }`}
                  onClick={() => setPaymentMethod(PaymentMethod.MB_REFERENCE)}
                  startIcon={<Multibanco />}
                  variant="outlined"
                ></Button>
              </div>

              <Button
                className='filled'
                onClick={() => setActiveStep(2)}
                disabled={!canProceedToStep(2)}
                variant="contained"
                disableElevation
              >
                Seguinte
              </Button>
            </StepContent>
          </Step>

          <Step key="2">
            <StepButton
              color="inherit"
              onClick={() => setActiveStep(2)}
              disabled={!canProceedToStep(2)}
            >
              Efetue pagamento
            </StepButton>
            <StepContent>
              {paymentMethod === PaymentMethod.MBWAY && (
                <div>
                  O pedido de pagamento irá ser enviado para o MBWay com o seu
                  número
                </div>
              )}
              <Button
                className='filled'
                onClick={() => launchPayment()}
                variant="contained"
                disableElevation
              >
                {paymentMethod === PaymentMethod.MB_REFERENCE
                  ? 'Gerar referência MB'
                  : 'Pagar'}
              </Button>
            </StepContent>
          </Step>

          <Step key="3">
            <StepButton color="inherit" disabled={!canProceedToStep(3)}>
              Conclusão
            </StepButton>
            <StepContent className="Purchase__summary">
              {purchaseState && <PurchaseStateSummary state={purchaseState} />}
            </StepContent>
          </Step>
        </Stepper>
      )}
    </Container>
  )
}

export default Purchase
