import { useContext, useMemo, useState } from 'react'
import AppStateContext from '../../contexts/AppStateContext'
import { Ticket, TicketingEvent } from '../../types'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'

import './TicketsBuyCart.scss'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import Purchase from '../Purchase/Purchase'
import { resolve } from 'path'

const IconImage = function (url: string) {
  return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={url} />
}

const Mbway = () => IconImage('logos/mbway.png')
const Multibanco = () => IconImage('logos/multibanco.png')
const BackTransfer = () => IconImage('logos/banktransfer.png')

const fetchBookTicket = async (
  apiBaseUrl: string,
  eventId: number,
  accessCategoryId: number
) => {
  const response = await fetch(
    `${apiBaseUrl}/ticketing/events/${eventId}/tickets/book`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        access_category_id: accessCategoryId,
      }),
      credentials: 'include',
    }
  )

  return response.json() as Promise<Ticket>
}

type ResponseResultData = {
  isVisible: boolean
  message: string
  type: 'error' | 'success'
}

type Props = {
  event: TicketingEvent
  memberHasExclusiveMemberTicket: boolean
  onCancel: () => void
  onComplete: () => void
}

function TicketsBuyCart({ event, memberHasExclusiveMemberTicket, onCancel, onComplete }: Props) {
  const { apiBaseUrl } = useContext(AppStateContext)

  const [isLoading, setIsLoading] = useState(false)
  const [purchaseDone, setPurchaseDone] = useState(false)

  const [ticketBuyingDialogVisible, setTicketBuyingDialogVisible] =
    useState(false)

  const [responseResult, setResponseResult] = useState<ResponseResultData>()

  const [ticketCategoryToBuy, setTicketCategoryToBuy] = useState<number>()

  const processPrePayment = async () => {
    const choosenCategory = event.accessCategories.find(
      (category) => category.id === ticketCategoryToBuy
    )

    if (!apiBaseUrl || !event?.id || !choosenCategory) {
      throw new Error('Missing required parameters')
    }

    setIsLoading(true)

    try {
      const data = await fetchBookTicket(
        apiBaseUrl,
        event.id,
        choosenCategory.id
      )

      return [(data as Ticket).uuid]
    } catch (error) {
      setResponseResult({
        isVisible: true,
        message:
          'Ocorreu um erro ao obter bilhetes. Por favor contacte o suporte.',
        type: 'error',
      })

      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const launchPayment = () => {
    setTicketBuyingDialogVisible(true)
  }

  const handlePurchaseComplete = () => {
    setPurchaseDone(true)
  }

  const itemsToPurchase = useMemo(() => {
    if (!ticketCategoryToBuy) return []

    const choosenCategory = event.accessCategories.find(
      (category) => category.id === ticketCategoryToBuy
    )

    if (!choosenCategory) return []

    return [
      {
        title: `${event.name} - ${choosenCategory.title}`,
        description: event.description,
        price: choosenCategory.price,
        quantity: 1,
      },
    ]
  }, [ticketCategoryToBuy])

  const isPurchaseOpened = useMemo(() => {
    return event.purchaseStart < new Date() && event.purchaseEnd > new Date()
  }, [event])

  return (
    <>
      <Container className="full-size flex-vertical flex-centered gap-16">
        {isPurchaseOpened ? <Typography variant="subtitle1">
          Selecione o tipo de bilhete a adquirir
        </Typography> : null}

        <div className="full-width flex-centered gap-16 flex-wrap">
          {event.accessCategories.map((category) => (
            <Tooltip key={category.id} arrow title={memberHasExclusiveMemberTicket && category.memberExclusive ? 'Já possuis um bilhete exclusivo a sócios para este evento.' : null}>
              <span>
                <Button
                  className={`TicketsBuyCart__item-button ${ticketCategoryToBuy === category.id &&
                    'TicketsBuyCart__item-button--selected'
                    }`}
                  onClick={() => setTicketCategoryToBuy(category.id)}
                  variant="outlined"
                  disabled={!category.enabled || (memberHasExclusiveMemberTicket && category.memberExclusive) || !isPurchaseOpened}
                >
                  {category.title} ({category.price}€)
                </Button>
              </span>
            </Tooltip>
          ))}
        </div>

        {isPurchaseOpened && <Button
          variant="contained"
          disableElevation
          startIcon={<ShoppingCartCheckoutIcon />}
          onClick={launchPayment}
          className="TicketsBuyCart__proceed-button"
          disabled={!ticketCategoryToBuy}
        >
          Prosseguir para pagamento
        </Button>}
      </Container>

      <Dialog open={ticketBuyingDialogVisible}>
        <DialogTitle>Adquirir bilhetes para {event.name}</DialogTitle>
        <DialogContent>
          <Purchase
            items={itemsToPurchase}
            onProceed={() => processPrePayment()}
            onComplete={() => handlePurchaseComplete()}
          ></Purchase>
        </DialogContent>
        <DialogActions>
          {purchaseDone && <Button onClick={() => onComplete()}>Fechar</Button>}
          {!purchaseDone && (
            <Button className='only-text' onClick={() => onCancel()}>Cancelar</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TicketsBuyCart
