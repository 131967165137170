import React, { useEffect, useState } from 'react'

import {
  CircularProgress,
  Container,
} from '@mui/material'
import { TicketingEvent, TicketingEventsResponse } from '../../types'
import useApi from '../../hooks/useApi'
import TicketingEventCard from '../TicketingEventCard/TicketingEventCard'
import { useNavigate } from 'react-router-dom'

const sortByDate = (a: TicketingEvent, b: TicketingEvent) => {
  return a.start.getTime() - b.start.getTime()
}

function NextEventCard() {
  const api = useApi()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [event, setEvent] = useState<TicketingEvent>()

  const getTicketingEvents = async () => {
    setIsLoading(true)

    try {
      const response = await api.get<TicketingEventsResponse>(`ticketing/events`)

      const events = response.data.map((event) => ({
        id: event.id,
        name: event.name,
        start: new Date(event.start),
        end: new Date(event.end),
        purchaseStart: new Date(event.purchase_start),
        purchaseEnd: new Date(event.purchase_end),
        checkinStart: new Date(event.checkin_start),
        checkinEnd: new Date(event.checkin_end),
        pictureUrl: event.picture_url,
        template: event.template ? event.template : 'details',
        description: event.description,
        local: event.local,
        accessCategories: (event.access_categories || []).map((category) => ({
          id: category.id,
          title: category.title,
          description: category.description,
          memberExclusive: category.member_exclusive,
          price: category.price,
          capacity: category.capacity,
          enabled: category.enabled,
        })),
      })).sort(sortByDate)

      if (events.length) {
        setEvent(events[0])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTicketingEvents();
  }, [])

  const goToTicketing = () => {
    navigate('/ticket-office')
  }

  if (isLoading) {
    <Container className="loading-container">
      <CircularProgress />
    </Container>
  }

  return event ? <TicketingEventCard event={event} mode='compact' onClick={goToTicketing} /> : null;
}

export default NextEventCard
