import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Card, Typography } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import QRCode from 'qrcode'

import { Ticket, TicketPaymentData, TicketingEvent, TicketsResponse } from '../../types'

import './TicketCard.scss'
import AppStateContext from '../../contexts/AppStateContext'


const fetchTicketPayment = async (apiBaseUrl: string, eventId: number, ticketUuid: string) => {
  const response = await fetch(`${apiBaseUrl}/ticketing/events/${eventId}/my-tickets/${ticketUuid}/payments`, {
    credentials: 'include',
  })

  return response.json() as Promise<TicketPaymentData>
}

type Props = { event: TicketingEvent; ticket: Ticket }

function TicketCard({ event, ticket }: Props) {
  const { apiBaseUrl } = useContext(AppStateContext)

  const [qrCode, setQrCode] = useState<string>()
  const [ticketPaymentData, setTicketPaymentData] = useState<TicketPaymentData>();

  useEffect(() => {
    if (!ticket || !apiBaseUrl) return

    const getPaymentData = async () => {
      const paymentData = await fetchTicketPayment(apiBaseUrl, event.id, ticket.uuid)
      setTicketPaymentData(paymentData)
    }

    const generateQrCode = async (content: string) => {
      const code = await QRCode.toDataURL(content)
      setQrCode(code)
    }

    getPaymentData();
    generateQrCode(ticket.uuid);
  }, [ticket])

  const eventCategory = useMemo(
    () =>
      ticket && event.accessCategories.find(
        (ac) => ac.id === ticket.eventAccessCategoryId
      ),
    [ticket]
  )

  return (
    <div className="TicketCard">
      <Card variant="outlined" className="TicketCard__header">
        <Typography variant="subtitle1">
          Bilhete {eventCategory?.title}
        </Typography>
      </Card>
      <Card variant="outlined" className="TicketCard__content">
        <div className="TicketCard__details">
          <div className="TicketCard__description">
            {eventCategory?.description}
          </div>
          <div className="TicketCard__price">{ticketPaymentData?.movement.value}€</div>
        </div>

        <img
          className={`TicketCard__qrcode ${!ticket.paid || ticket.used ? 'TicketCard__qrcode--inactive' : ''
            }`}
          src={qrCode}
        />

        <div
          className={`TicketCard__state-overlay ${!ticket.paid ? 'TicketCard__state-overlay--unpaid' : ''
            } ${ticket.used ? 'TicketCard__state-overlay--used' : ''}`}
        >
          {ticket.used ? 'USADO' : !ticket.paid ? 'PENDENTE' : ''}
        </div>
      </Card>
    </div>
  )
}

export default TicketCard
