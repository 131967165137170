import { createContext } from 'react'
import { MemberSituationContextData } from '../types'

export default createContext<MemberSituationContextData>({
  situation: {
    periods: [],
    pendingTotal: 0,
  },
  setSituation: () => {},
  updateSituation: () => {},
})
