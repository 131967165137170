import React, { useContext } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material'
import { useState } from 'react'
import PaymentInfo from '../PaymentInfo/PaymentInfo'
import PeriodsTable from '../PeriodsTable/PeriodsTable'

import './MembershipPeriods.css'

import MemberSituationContext from '../../contexts/MemberSituationContext'
import MembershipStatusCard from '../MembershipStatusCard/MembershipStatusCard'
import AppStateContext from '../../contexts/AppStateContext'

function MembershipPeriods() {
  const appState = useContext(AppStateContext)
  const { member } = appState
  const memberSituationContext = useContext(MemberSituationContext)

  const [expandedPeriods, setExpandedPeriods] = useState(false)

  if (!member || !memberSituationContext.situation) {
    return <></>
  }

  const { pendingTotal, periods } = memberSituationContext.situation

  const pendingPeriods = periods.filter((period) => period.status === 'MISSING' || period.status === 'PENDING')

  const isRegularized = pendingTotal === 0 && !pendingPeriods.length

  return (
    <div className="membership-periods-wrapper">
      <MembershipStatusCard situation={memberSituationContext.situation} />

      {!isRegularized && (
        <Card className="payment-data-header">
          <PaymentInfo
            periods={pendingPeriods}
            value={pendingTotal}
          ></PaymentInfo>
        </Card>
      )}

      <div className="membership-periods-details">
        <Accordion
          expanded={expandedPeriods}
          onChange={() =>
            expandedPeriods
              ? setExpandedPeriods(false)
              : setExpandedPeriods(true)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Table
              sx={{ minWidth: 350, maxWidth: 500 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  key="totals"
                  className="total"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" colSpan={3}>
                    Extrato detalhado de quotização
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </AccordionSummary>
          <AccordionDetails>
            <Divider light />
            <PeriodsTable periods={periods}></PeriodsTable>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
export default MembershipPeriods
