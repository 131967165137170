import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withStyles,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import { MuiTelInput, MuiTelInputCountry, matchIsValidTel } from 'mui-tel-input'
import AppStateContext from '../../contexts/AppStateContext'

import './AccountCreate.css'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { ErrorType } from '../../types/error-type'
import { DEFAULT_COUNTRY, PREFERED_COUNTRIES } from '../../constants'
import { isValidEmail, isValidMsisdn, isValidPassword } from '../../validators'

const fetchRequestCode = async (
  apiBaseUrl: string,
  memberNumber: number,
  msisdn: string,
  email?: string,
  code?: string
) => {
  try {
    const response = await fetch(`${apiBaseUrl}/activate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        member_number: memberNumber,
        msisdn,
        email,
        code,
      }),
    })

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`)
    }

    if (response.status === 200) {
      return response.json()
    }
  } catch (error) {
    throw new Error(`Error: ${error}`)
  }
}

const fetchRequestRegister = async (
  apiBaseUrl: string,
  memberNumber: number,
  msisdn: string,
  email: string | null = null,
  password: string,
  code: string,
  control: string
) => {
  try {
    const response = await fetch(`${apiBaseUrl}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        member_number: memberNumber,
        msisdn,
        email,
        password,
        code,
        control,
      }),
    })

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`)
    }

    if (response.status === 200) {
      return response
    }
  } catch (error) {
    throw new Error(`Error: ${error}`)
  }
}

function AccountCreate() {
  const { apiBaseUrl, iconFile, member } = useContext(AppStateContext)

  const [activeStep, setActiveStep] = useState(0)

  const [memberNumber, setMemberNumber] = useState<number>()
  const [email, setEmail] = useState<string>()
  const [msisdn, setMsisdn] = useState<any>()
  const [code, setCode] = useState<string>()

  const [password, setPassword] = useState<string>()
  const [confirmationPassword, setConfirmationPassword] = useState<string>()

  const [controlCode, setControlCode] = useState<string>()
  const [requesting, setRequesting] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [registerError, setRegisterError] = useState<ErrorType | null>(null)

  const [memberNumberTouched, setMemberNumberTouched] = useState(false)

  const validMemberNumber = memberNumber ? !isNaN(memberNumber) : false
  const validMemberNumberField = memberNumber === undefined || validMemberNumber

  const validEmail = isValidEmail(email)
  const validEmailField = email === undefined || validEmail

  const validMsisdn = isValidMsisdn(msisdn)
  const validMsisdnField = msisdn === undefined || validMsisdn

  const validPassword = isValidPassword(password)
  const validPasswordField = password === undefined || validPassword

  const validConfirmationPassword = password === confirmationPassword
  const validConfirmationPasswordField =
    confirmationPassword === undefined || validConfirmationPassword

  const canActivate =
    apiBaseUrl && validMemberNumber && validMsisdn && validEmail

  const canRegister =
    canActivate &&
    validPassword &&
    password === confirmationPassword &&
    code &&
    controlCode

  const prepareRequestCode = () => {
    if (!canActivate) {
      if (!validMemberNumber) {
        setActiveStep(0)
      } else if (!validEmail) {
        setActiveStep(1)
      } else if (!validMsisdn) {
        setActiveStep(2)
      }

      return
    }

    setRequesting(true)

    fetchRequestCode(
      apiBaseUrl,
      memberNumber as number,
      msisdn.replace(/[\s]/g, ''),
      email,
      controlCode
    )
      .then((response: any) => {
        setControlCode(response.code)
        setActiveStep(3)
      })
      .catch((error) => {
        setRequestError(error)
      })
      .finally(() => setRequesting(false))
  }

  const register = () => {
    if (!canRegister) {
      return
    }

    setRequesting(true)
    setRegisterError(null)

    fetchRequestRegister(
      apiBaseUrl,
      memberNumber as number,
      msisdn.replace(/[\s]/g, ''),
      email,
      password as string,
      code,
      controlCode
    )
      .then(() => {
        setActiveStep(5)
      })
      .catch((error) => {
        setActiveStep(3)
        setRegisterError(ErrorType.INVALID_REGISTER_CODE)
      })
      .finally(() => setRequesting(false))
  }

  useEffect(() => {
    setRequestError(null)
  }, [activeStep])

  useEffect(() => {
    if (member) {
      setMemberNumber(member.number)
      setEmail(member.email)
      setMsisdn(member.msisdn)
    }
  }, [])

  const handleNumberBlur = () => {
    setMemberNumberTouched(true)
  }

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseInt(event.target.value)

      if (isNaN(value)) {
        setMemberNumber(undefined)
      } else {
        setMemberNumber(value)
      }
    } catch (error) {}
  }

  return (
    <Container className="account-create-wrapper">
      <img width="150px" src={iconFile} />

      {activeStep < 5 ? (
        <div>
          <h3>Introduza os seus dados</h3>

          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation="vertical"
            style={{ marginBottom: '24px' }}
          >
            <Step key={0} completed={!!controlCode}>
              <StepLabel onClick={() => !controlCode && setActiveStep(0)}>
                Introduza o seu número de sócio{' '}
                {activeStep > 0 ? 'Nº ' + memberNumber : ''}
              </StepLabel>
              <StepContent>
                <TextField
                  id="outlined-basic"
                  required
                  label="Número de sócio"
                  variant="filled"
                  name="nome"
                  value={memberNumber}
                  onBlur={handleNumberBlur}
                  onChange={handleNumberChange}
                  error={!validMemberNumberField || (memberNumberTouched && !validMemberNumber)}
                />
                <Button
                  variant="text"
                  onClick={() => setActiveStep(1)}
                  disabled={!validMemberNumber}
                  className="ok-button"
                >
                  OK
                </Button>
              </StepContent>
            </Step>

            <Step key={1} completed={!!controlCode}>
              <StepLabel onClick={() => !controlCode && setActiveStep(1)}>
                Introduza o seu e-mail
              </StepLabel>
              <StepContent>
                <TextField
                  id="outlined-basic"
                  required
                  label="E-mail"
                  variant="filled"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={!validEmailField}
                />
                <Button
                  variant="text"
                  onClick={() => setActiveStep(2)}
                  className="filled ok-button"
                >
                  OK
                </Button>
              </StepContent>
            </Step>

            <Step key={2} completed={!!controlCode}>
              <StepLabel onClick={() => !controlCode && setActiveStep(2)}>
                Introduza o seu número de telefone
              </StepLabel>
              <StepContent>
                {requesting ? (
                  <CircularProgress />
                ) : requestError ? (
                  <Box flexDirection="column">
                    <Alert severity="error">
                      Os seus dados não são válidos ou ocorreu um problema. Se o
                      problema persistir, por favor contacte a direção
                    </Alert>

                    <Button variant="text" onClick={() => prepareRequestCode()}>
                      Tentar novamente
                    </Button>
                  </Box>
                ) : (
                  <>
                    <MuiTelInput
                      defaultCountry={DEFAULT_COUNTRY}
                      preferredCountries={PREFERED_COUNTRIES}
                      forceCallingCode={true}
                      focusOnSelectCountry={true}
                      value={msisdn}
                      onChange={setMsisdn}
                      error={!validMsisdnField}
                    />
                    <Button
                      variant="text"
                      onClick={() => prepareRequestCode()}
                      disabled={!validMsisdn}
                      className="ok-button"
                    >
                      OK
                    </Button>
                  </>
                )}
              </StepContent>
            </Step>

            <Step key={3} completed={activeStep >= 5}>
              <StepLabel
                onClick={() =>
                  activeStep >= 3 && activeStep < 5 && setActiveStep(3)
                }
              >
                Introduza o código que recebeu no seu e-mail ou telefone
              </StepLabel>
              <StepContent>
                <Box flexDirection="column">
                  <div>
                    <TextField
                      id="outlined-basic"
                      required
                      label="Código de autenticação"
                      value={code}
                      variant="filled"
                      name="code"
                      onChange={(event) => setCode(event.target.value)}
                    />
                    <Button
                      variant="text"
                      onClick={() => setActiveStep(4)}
                      className="ok-button"
                    >
                      OK
                    </Button>
                  </div>

                  {registerError === ErrorType.INVALID_REGISTER_CODE && (
                    <>
                      <Alert severity="error" style={{ marginTop: '16px' }}>
                        O código fornecido não é válido
                      </Alert>
                    </>
                  )}

                  {registerError === ErrorType.REQUEST_ERROR && (
                    <>
                      <Alert severity="error" style={{ marginTop: '16px' }}>
                        Ocorreu um erro ao enviar os seus dados. Se o problema
                        persistir, por favor contacte a direção
                      </Alert>
                    </>
                  )}
                </Box>
              </StepContent>
            </Step>

            <Step key={4} completed={activeStep === 5}>
              <StepLabel
                onClick={() =>
                  activeStep >= 3 && activeStep < 5 && setActiveStep(4)
                }
              >
                Defina a sua password
              </StepLabel>
              <StepContent>
                {requesting ? (
                  <CircularProgress />
                ) : (
                  <Box flexDirection="column">
                    <Box
                      className="flex-vertical flex-form"
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        error={!validPasswordField}
                        id="outlined-basic"
                        required
                        label="Password"
                        variant="filled"
                        name="password"
                        type="password"
                        helperText="A password deve ter no mínimo 8 dígitos, contendo pelo menos, 1 número e 1 letra"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setPassword(event.target.value)}
                        value={password}
                      />

                      <TextField
                        error={!validConfirmationPasswordField}
                        id="outlined-basic"
                        required
                        label="Confirme a sua password"
                        variant="filled"
                        name="confirmationpassword"
                        type="password"
                        helperText={
                          !validConfirmationPasswordField
                            ? 'A password não corresponde à inserida acima'
                            : null
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setConfirmationPassword(event.target.value)}
                        value={confirmationPassword}
                      />

                      <Button
                        disabled={!canRegister}
                        variant="contained"
                        onClick={() => register()}
                        className="ok-button"
                      >
                        Registar
                      </Button>
                    </Box>
                  </Box>
                )}
              </StepContent>
            </Step>

            <Step key={5} completed={activeStep === 5}>
              <StepLabel>Concluído</StepLabel>
              <StepContent></StepContent>
            </Step>
          </Stepper>

          <Box>
            <Button variant="text" href="/" style={{ color: 'white' }}>
              CANCELAR
            </Button>
          </Box>
        </div>
      ) : (
        <Box className="completed-message">
          <Box>
            <CheckCircle style={{ color: 'rgb(0, 180, 121)' }}></CheckCircle>
            <Typography gutterBottom variant="h5" style={{ fontWeight: '400' }}>
              Já pode iniciar sessão com o seu número de sócio e a password que
              definiu!
            </Typography>
          </Box>
          <Box>
            <Button
              style={{ marginTop: '16px' }}
              variant="contained"
              href="/#/login"
            >
              Login
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default AccountCreate
