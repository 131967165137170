import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

type Props = {
  error: Error
  resetErrorBoundary: () => void
}

function AppErrorBoundary({ error, resetErrorBoundary }: Props) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.error(error)

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}

export default AppErrorBoundary
